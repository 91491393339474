import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { ApplicationState } from '@store/types'
import { Box, GridStyled, Loading, Separator } from '@atoms'
import { Contrato, ContratoSelecionado, Matricula } from '@store/refinanciamento/contratos/types'
import * as actions from '@store/refinanciamento/contratos/actions'
import {
  ConveniosCodes,
  ConveniosMultiContratosBloqueado,
  PendenciaEnumV3,
  StepRefin,
} from '@utils/enums'
import {
  clearSimulacao,
  setDadosSimulacaoAlterados,
  simulacaoRequest,
  trackingRefin,
} from '@store/refinanciamento/simulacao/actions'
import { CallbackSimulacaoType, InfoParcelas } from '@store/refinanciamento/simulacao/types'
import { useMobileUserInfo, useTrackingRefin } from '@hooks'
import { formatCurrency } from '@utils/masks'
import IcWarningCircle from '@interco/icons/core/status/ic_warning_circle'
import IcDocument from '@interco/icons/core/files/ic_document'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import Tag from '@utils/Tag'
import routes from '@routes/RefinV2/routes'
import routesV1 from '@routes/Refin/routes'
import { Alert } from '@interco/inter-ui/components/Alert'
import { openLinkByEnviroment, timestampToDatePt } from '@utils/functions'
import { setEmail, setTelefone } from '@store/refinanciamento/contato/actions'
import {
  setConta,
  setCpf,
  setDataNascimento,
  setNome,
} from '@store/refinanciamento/dadosPessoais/actions'
import { setConta as setContaDadosBancarios } from '@store/refinanciamento/dadosBancarios/actions'
import { interWbSession, IWbTic } from '@interco/inter-webview-bridge'

import { dialogVariants, VariantDialogContratos } from './Bottomsheets/dialogVariants'
import { Grid, GridScroll, LabelDiv, Line, LinkSite } from './styles'
import { BottomSheetsContrato } from './Bottomsheets'
import BottomsheetInss from './BottomsheetInss'

const ticBotaoContinuar = () => {
  const ticProps: IWbTic = {
    name: 'btn_continuar_refin',
    module: 'refinanciamento_consignado',
    action: 'continuar_refinanciamento',
    params: {},
  }

  interWbSession.tic(ticProps)
}

const Contratos = () => {
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [showBottomSheetInss, setShowBottomSheetInss] = useState(false)
  const [variantDialog, setVariantDialog] = useState<VariantDialogContratos>(
    dialogVariants.SaibaMais,
  )

  const { listaContratos, matriculaSelecionada, contratosSelecionados, convenioCodigo } =
    useSelector((state: ApplicationState) => state.refinanciamento.contratos)
  const { utmCampaign } = useSelector((state: ApplicationState) => state.session)

  const loading = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao.loadingSimulacao,
  )
  const { loading: loadingInss } = useSelector(
    (state: ApplicationState) => state.refinanciamento.consultaMargemInss,
  )

  const userInfo = useMobileUserInfo()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const disableContinue = () => !contratosSelecionados?.length
  useTrackingRefin(StepRefin.CONTRATOS)

  // GET USER INFO
  useEffect(() => {
    if (userInfo) {
      dispatch(setTelefone(userInfo.cellPhoneNumber || ''))
      dispatch(setEmail(userInfo.email || ''))
      dispatch(setCpf(userInfo.cpf || ''))
      dispatch(setNome(userInfo.name || ''))
      dispatch(setConta(userInfo.account || ''))
      dispatch(setContaDadosBancarios(userInfo.account || ''))
      dispatch(setDataNascimento(timestampToDatePt(Number(userInfo.birthDate)) || ''))
    }
  }, [userInfo, dispatch])

  useEffect(() => {
    Tag.enviarTagueamentoApp({
      name: 'CONSIGNADO_REFIN_ACESSO',
      ref_figma: '4',
      tela: 'Refinanciamento Consig',
      content_action: 'Dado carregado',
      utm_campaign: utmCampaign,
      product: 'REFINANCIAMENTO_CONSIGNADO',
    })
  }, [utmCampaign])

  const hasContratoSelecionado = (contrato: string) =>
    contratosSelecionados?.some((item) => item.numeroContrato === contrato)

  const disableMultiContratos = (contrato: Contrato) =>
    Boolean(
      ConveniosMultiContratosBloqueado.indexOf(contrato.convenioDesc) >= 0 &&
        contratosSelecionados?.length &&
        contratosSelecionados[0].numeroContrato !== contrato.numeroContrato,
    )

  const disabledCheckbox = (matricula: string, contrato: Contrato) =>
    !(matricula === matriculaSelecionada || matriculaSelecionada === '') ||
    disableMultiContratos(contrato)

  const adicionaContrato = (contrato: ContratoSelecionado) => {
    dispatch(actions.setContratosSelecionados([...(contratosSelecionados || []), contrato]))
    dispatch(actions.setConvenioSelecionado(contrato.convenioDesc || ''))
    dispatch(actions.setConvenioCodigoSelecionado(contrato.convenioId))
    dispatch(actions.setOrgaoSelecionado(contrato.orgaoDesc || ''))
    dispatch(actions.setOrgaoCodigoSelecionado(contrato.orgaoId))
    dispatch(actions.setMatriculaSelecionada(contrato.matricula || ''))
    dispatch(actions.setCodProduto(contrato.codProduto))
  }

  const removeContrato = (contrato: string) => {
    const novaLista = contratosSelecionados?.filter((item) => item.numeroContrato !== contrato)
    if (novaLista?.length === 0) {
      dispatch(actions.setMatriculaSelecionada(''))
      dispatch(actions.setConvenioSelecionado(''))
      dispatch(actions.setConvenioCodigoSelecionado(0))
      dispatch(actions.setOrgaoSelecionado(''))
      dispatch(actions.setOrgaoCodigoSelecionado(0))
      dispatch(actions.setCodProduto(0))
    }
    dispatch(actions.setContratosSelecionados(novaLista || []))
  }

  const selecionarTodos = (listaMatriculas: Matricula) => {
    const contratosSelecionadosTemp: ContratoSelecionado[] = []

    listaMatriculas.listContrato.forEach((contrato) => {
      if (!contrato.indisponivel) {
        const contratoJaAdicionado = contratosSelecionados.some(
          (c) => c.numeroContrato === contrato.numeroContrato,
        )

        if (!contratoJaAdicionado) {
          contratosSelecionadosTemp.push({
            ...contrato,
            matricula: listaMatriculas.numeroMatricula,
          })
        }
      }
    })
    dispatch(
      actions.setContratosSelecionados([
        ...(contratosSelecionados || []),
        ...contratosSelecionadosTemp,
      ]),
    )
    dispatch(actions.setConvenioSelecionado(contratosSelecionadosTemp[0].convenioDesc || ''))
    dispatch(actions.setConvenioCodigoSelecionado(contratosSelecionadosTemp[0].convenioId))
    dispatch(actions.setOrgaoSelecionado(contratosSelecionadosTemp[0].orgaoDesc || ''))
    dispatch(actions.setOrgaoCodigoSelecionado(contratosSelecionadosTemp[0].orgaoId))
    dispatch(actions.setMatriculaSelecionada(contratosSelecionadosTemp[0].matricula || ''))
    dispatch(actions.setCodProduto(contratosSelecionadosTemp[0].codProduto))
  }

  const removerTodos = () => {
    dispatch(actions.setMatriculaSelecionada(''))
    dispatch(actions.setConvenioSelecionado(''))
    dispatch(actions.setConvenioCodigoSelecionado(0))
    dispatch(actions.setOrgaoSelecionado(''))
    dispatch(actions.setOrgaoCodigoSelecionado(0))
    dispatch(actions.setCodProduto(0))
    dispatch(actions.setContratosSelecionados([]))
  }

  const verificarSelecaoPorMatricula = (listaMatriculas: Matricula) => {
    const todosContratosSelecionados = listaMatriculas.listContrato.every((contrato) =>
      contratosSelecionados.some(
        (contratoSelecionado) => contrato.numeroContrato === contratoSelecionado.numeroContrato,
      ),
    )

    return todosContratosSelecionados
  }

  const hasContratoInss = () => contratosSelecionados?.some((item) => item.convenioDesc === 'INSS')

  const callWebTag = (redirectUrl: string) => {
    Tag.enviarTagueamentoWeb({
      element_name: 'Continuar',
      section_name: 'Refinanciamento Consig',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${redirectUrl}`,
      step: 'start',
    })
  }

  const navegaSelecaoSimulacao = (
    simulacoes: InfoParcelas[],
    simulacoesComSeguro: InfoParcelas[],
  ) => {
    // Nesse ponto aqui verifica se for INSS/SIAPE manda para fluxo novo, se não for manda pra fluxo antigo.
    if (
      convenioCodigo === ConveniosCodes.INSS ||
      convenioCodigo === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL
    ) {
      if (simulacoesComSeguro?.length > 0) {
        callWebTag(routes.OFERTA_SEGURO)
        navigate(routes.OFERTA_SEGURO)
      } else if (simulacoes?.length > 0) {
        callWebTag(routes.RESULTADO_SIMULACAO)
        dispatch(setDadosSimulacaoAlterados(true))
        navigate(routes.RESULTADO_SIMULACAO)
      } else {
        callWebTag(routes.NAO_LIBERADO)
        navigate(routes.NAO_LIBERADO)
      }
    } else if (simulacoes?.length > 0 && simulacoesComSeguro?.length > 0) {
      callWebTag(routesV1.SIMULACAO)
      navigate(routesV1.SIMULACAO)
    } else if (
      (simulacoes?.length > 0 && simulacoesComSeguro?.length === 0) ||
      (simulacoes?.length === 0 && simulacoesComSeguro?.length > 0)
    ) {
      callWebTag(routesV1.CONFIRMAR_CONTATO)
      navigate(routesV1.CONFIRMAR_CONTATO)
    } else {
      callWebTag(routesV1.NAO_LIBERADO)
      navigate(routesV1.NAO_LIBERADO)
    }
  }

  return (
    <Page
      positionFooter="sticky"
      stickyContainerFooterBottom="0"
      stickyFooter={
        <>
          <Separator margin="8px -24px" height="1px" />
          <Alert type="grayscale">
            Ao começar a simulação você concorda com a{' '}
            <LinkSite
              onClick={async () => {
                Tag.enviarTagueamentoWeb({
                  element_name: 'Política de Privacidade',
                  section_name: 'Onboarding',
                  element_action: 'click button',
                  c_page: window.location.href,
                  redirect_url: `${process.env.REACT_APP_POLITICA_PRIVACIDADE}`,
                  step: 'start',
                })
                openLinkByEnviroment(`${process.env.REACT_APP_POLITICA_PRIVACIDADE}`)
              }}
            >
              Política de Privacidade.
            </LinkSite>
          </Alert>
          <Button
            fullWidth
            disabled={disableContinue() || loading || loadingInss}
            isLoading={loading || loadingInss}
            variant="primary"
            onClick={() => {
              Tag.enviarTagueamentoApp({
                name: 'CONSIGNADO_REFIN_FLUXO',
                ref_figma: '1',
                tela: 'Refinanciamento Consig',
                ref_type: 'Botao',
                content_action: 'Toque',
                content_name: 'Continuar',
                product: 'REFINANCIAMENTO_CONSIGNADO',
              })
              dispatch(clearSimulacao())
              if (hasContratoInss()) {
                setShowBottomSheetInss(true)
              } else {
                ticBotaoContinuar()
                dispatch(
                  simulacaoRequest({
                    callback: ({
                      pending,
                      simulations,
                      simulationsWithInsurance,
                    }: CallbackSimulacaoType) => {
                      dispatch(trackingRefin(StepRefin.SIMULAR_CONTRATOS))
                      if (pending === PendenciaEnumV3.PENDENCIA_IRREVERSIVEL) {
                        navigate(routes.NAO_LIBERADO)
                      } else {
                        navegaSelecaoSimulacao(simulations, simulationsWithInsurance)
                      }
                    },
                  }),
                )
              }
            }}
          >
            Continuar
          </Button>
          <div />
        </>
      }
    >
      <TextParagraph variant="headline-h2" fontWeight="600" margin="0 0 8px 0">
        Selecione os contratos que deseja refinanciar
      </TextParagraph>

      <TextParagraph variant="body-3">
        É possível selecionar vários contratos do mesmo convênio, matrícula e ou benefício
      </TextParagraph>
      <Separator margin="24px -24px" height="1px" />
      <Grid>
        {listaContratos?.length ? (
          listaContratos?.map((contratosAtivos) => (
            <GridScroll key={contratosAtivos.descricaoConvenio}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <IcDocument
                  width={24}
                  height={24}
                  color={Colors.GRAY500}
                  style={{ marginRight: '4px' }}
                />
                <TextParagraph variant="headline-h3" margin="8px 0 8px 0" fontWeight="600">
                  {contratosAtivos.descricaoConvenio}
                </TextParagraph>
              </div>

              {contratosAtivos.listMatricula.map((listaMatriculas, index) => (
                <Grid key={listaMatriculas.numeroMatricula}>
                  {index !== 0 && <Separator variant="small" margin="24px -24px" height="1px" />}
                  <Line>
                    <TextParagraph variant="body-3" fontWeight="700">
                      {`${
                        contratosAtivos.descricaoConvenio === 'INSS' ? 'Benefício:' : 'Matrícula:'
                      } ${listaMatriculas.numeroMatricula}`}
                    </TextParagraph>
                    <ColorWrapper
                      color={
                        !disabledCheckbox(
                          listaMatriculas.numeroMatricula,
                          listaMatriculas.listContrato[0],
                        ) && listaMatriculas.listContrato.length > 1
                          ? Colors.PRIMARY500
                          : Colors.GRAY300
                      }
                      onClick={() => {
                        if (
                          disabledCheckbox(
                            listaMatriculas.numeroMatricula,
                            listaMatriculas.listContrato[0],
                          )
                        ) {
                          setVariantDialog(dialogVariants.SelecaoContratos)
                          setShowBottomSheet(true)
                        } else if (
                          listaMatriculas.listContrato.length > 1 &&
                          listaMatriculas.listContrato.length === contratosSelecionados.length
                        ) {
                          removerTodos()
                        } else if (listaMatriculas.listContrato.length > 1) {
                          selecionarTodos(listaMatriculas)
                        }
                      }}
                    >
                      <TextParagraph variant="body-3" fontWeight="700">
                        {verificarSelecaoPorMatricula(listaMatriculas) &&
                        listaMatriculas.listContrato.length > 1
                          ? 'Excluir seleção'
                          : 'Selecionar todos'}
                      </TextParagraph>
                    </ColorWrapper>
                  </Line>

                  {listaMatriculas.listContrato.map((contrato, contratoIndex) => {
                    if (contrato.indisponivel) {
                      return (
                        <Box
                          justify="space-between"
                          margin="16px 0px"
                          key={contrato.numeroContrato}
                        >
                          <Grid
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                            onClick={() => {
                              if (disabledCheckbox(listaMatriculas.numeroMatricula, contrato)) {
                                setVariantDialog(dialogVariants.SelecaoContratos)
                                setShowBottomSheet(true)
                              } else {
                                setVariantDialog(dialogVariants.Indisponivel)
                                setShowBottomSheet(true)
                              }
                            }}
                          >
                            <LabelDiv>
                              <ColorWrapper
                                color={
                                  disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                    ? '#'
                                    : Colors.GRAY500
                                }
                                fontWeight="600"
                              >
                                <TextParagraph
                                  variant="body-3"
                                  margin="0px 0px 4px 0px"
                                  fontWeight="600"
                                >
                                  Valor da parcela
                                </TextParagraph>
                              </ColorWrapper>

                              <ColorWrapper
                                color={
                                  disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                    ? '#'
                                    : Colors.GRAY500
                                }
                              >
                                <TextParagraph variant="body-3" margin="0px 0px 8px 0px">
                                  {formatCurrency(contrato.valorProximaParcela)}
                                </TextParagraph>
                              </ColorWrapper>
                              <ColorWrapper
                                color={
                                  disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                    ? '#'
                                    : Colors.GRAY400
                                }
                                margin="0"
                              />
                            </LabelDiv>
                            <Checkbox
                              disabled
                              reversed
                              id={`checkbox-contrato-${contrato.numeroContrato}-${{
                                contratoIndex,
                              }}`}
                              checked={false}
                              label={
                                <IcWarningCircle height={24} width={24} color={Colors.ALERT500} />
                              }
                            />
                          </Grid>
                        </Box>
                      )
                    }
                    return (
                      <Box
                        justify="flex-start"
                        margin="16px 0px"
                        key={contrato.numeroContrato}
                        actived={hasContratoSelecionado(contrato.numeroContrato)}
                      >
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                          onClick={() => {
                            if (disabledCheckbox(listaMatriculas.numeroMatricula, contrato)) {
                              setVariantDialog(dialogVariants.SelecaoContratos)
                              setShowBottomSheet(true)
                            }
                          }}
                        >
                          <LabelDiv>
                            <ColorWrapper
                              color={
                                disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                  ? '#'
                                  : Colors.GRAY500
                              }
                              fontWeight="600"
                            >
                              <TextParagraph
                                variant="body-3"
                                margin="0px 0px 4px 0px"
                                fontWeight="600"
                              >
                                Valor da parcela
                              </TextParagraph>
                            </ColorWrapper>
                            <ColorWrapper
                              color={
                                disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                  ? '#'
                                  : Colors.GRAY500
                              }
                            >
                              <TextParagraph variant="body-3" margin="0px 0px 8px 0px">
                                {formatCurrency(contrato.valorProximaParcela)}
                              </TextParagraph>
                            </ColorWrapper>

                            <ColorWrapper
                              color={
                                disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                  ? '#'
                                  : Colors.GRAY400
                              }
                              margin="0"
                            />
                          </LabelDiv>
                          <Checkbox
                            reversed
                            disabled={disabledCheckbox(listaMatriculas.numeroMatricula, contrato)}
                            id={`checkbox-contrato-${contrato.numeroContrato}-${{
                              contratoIndex,
                            }}`}
                            checked={hasContratoSelecionado(contrato.numeroContrato)}
                            onChange={() => {
                              if (
                                contratosSelecionados?.length &&
                                hasContratoSelecionado(contrato.numeroContrato)
                              ) {
                                removeContrato(contrato.numeroContrato)
                              } else {
                                adicionaContrato({
                                  ...contrato,
                                  matricula: listaMatriculas.numeroMatricula,
                                })
                              }
                            }}
                          />
                        </Grid>
                      </Box>
                    )
                  })}
                </Grid>
              ))}
            </GridScroll>
          ))
        ) : (
          <GridStyled>
            <Loading />
          </GridStyled>
        )}
      </Grid>
      {showBottomSheetInss ? (
        <BottomsheetInss setShowBottomsheetInss={setShowBottomSheetInss} />
      ) : (
        <></>
      )}
      {showBottomSheet ? (
        <BottomSheetsContrato
          setShowBottomSheet={setShowBottomSheet}
          variant={variantDialog}
          customTextButton="Entendi"
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default Contratos
