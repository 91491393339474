import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { Page } from '@templates'
import { Camera } from '@interco/cp-react-ui-lib'
import routesV2 from '@routes/RefinV2/routes'
import { useWindowSize } from '@hooks'
import { setHideFooter, setHideHeader } from '@store/ui/navigation/actions'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
  setRollbackRouterError,
} from '@store/refinanciamento/contrachequeDocumentos/actions'
import { ReviewFoto } from '@organisms/ReviewFoto'

const Foto = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [habilitaCamera, setHabilitaCamera] = useState(true)
  const [foto, setFoto] = useState('')
  const [fullScreenMobile, setFullScreenMobile] = useState(false)

  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contrachequeDocumentos,
  )
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  const size = useWindowSize()

  useEffect(() => {
    if (size.width < 680) {
      setFullScreenMobile(true)
    } else {
      setFullScreenMobile(false)
    }
    return () => {
      setFullScreenMobile(false)
    }
  }, [size])

  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  useEffect(() => {
    if (isDone) {
      navigate(routesV2.STATUS_DOCUMENTO)
    }
  }, [navigate, convenioCodigo, isDone])

  useEffect(() => {
    if (isApp || fullScreenMobile) {
      dispatch(setHideFooter(true))
      dispatch(setHideHeader(true))
    } else {
      dispatch(setHideFooter(false))
      dispatch(setHideHeader(false))
    }
  }, [dispatch, fullScreenMobile, isApp])

  const onConfirmReview = (fotografia: string) => {
    if (anexos.length === 1) {
      dispatch(setIsLoading(true))
      dispatch(setIsError(false))
      dispatch(setRollbackRouterError(routesV2.STATUS_DOCUMENTO))
      dispatch(sendToS3({ tipo: 'CONTRACHEQUE', anexos }))
    } else {
      const img = new Image()
      img.onload = () => {
        dispatch(
          setAnexos({
            anexo: {
              id: uuidv4(),
              nome: `CONTRACHEQUE.jpeg`,
              tipoExtensao: 'jpeg',
              blobs: [
                {
                  height: img.height,
                  width: img.width,
                  data: fotografia,
                },
              ],
            },
            tipo: 'CONTRACHEQUE',
          }),
        )
        if (anexos.length === 1) {
          dispatch(setIsLoading(true))
          dispatch(setIsError(false))
          dispatch(setRollbackRouterError(routesV2.STATUS_DOCUMENTO))
          dispatch(sendToS3({ tipo: 'CONTRACHEQUE', anexos }))
        }
      }
      img.src = fotografia
    }
  }

  const tipoTexto = () => 'Frente do Contra Cheque'

  return habilitaCamera ? (
    <Page>
      <Camera
        audio={false}
        mask="secondary"
        mode="photo"
        facing="back"
        mobile={isApp || fullScreenMobile}
        onCapturedPhoto={(fotografia) => {
          setHabilitaCamera(false)
          setFoto(String(fotografia))
        }}
        titleDescription={tipoTexto()}
      />
    </Page>
  ) : (
    <ReviewFoto
      fotografia={foto}
      onConfirm={onConfirmReview}
      setHabilitaCamera={() => {
        setHabilitaCamera(true)
      }}
      isLoading={isLoading}
      forceIsMobile={fullScreenMobile}
    />
  )
}

export default Foto
