import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fileToBase64 } from '@utils/functions'
import ListCheck from '@interco/icons/core/action-navigation/ic_checklist'
import CheckBack from '@interco/icons/core/finance/ic_money_check_back'
import FrameDocument from '@interco/icons/core/action-navigation/ic_expand'
import Attachment from '@interco/icons/core/action-navigation/ic_attachment'
import CameraOn from '@interco/icons/core/media-communication/ic_photo'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
} from '@store/refinanciamento/contrachequeDocumentos/actions'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { StepRefin } from '@utils/enums'
import { Page } from '@templates'
import routes from '@routes/RefinV2/routes'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Loading } from '@atoms'
import Tag from '@utils/Tag'

import { Container, CustomButton, DivLine, InputFile, LineDescription } from '../styles'

const MaxFileSize = 2000
const ModoEnvioContracheque = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openLoading, setOpenLoading] = useState(false)
  const [bottomSheetSize, setBottomSheetSize] = useState(false)
  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contrachequeDocumentos,
  )
  const anexosSizes = anexos?.map((anexo) => anexo?.anexo?.size)
  const somaSizes =
    Number(anexosSizes.reduce((total, numero) => Number(total) + Number(numero), 0)) / 1000

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Foto do documento', true)
    }
  }, [])

  useEffect(() => {
    if (somaSizes > MaxFileSize) setBottomSheetSize(true)
  }, [somaSizes])

  const anexarDocumentos = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    const size = file?.size && file?.size / 1000

    if ((size && size > MaxFileSize) || somaSizes + Number(size) > MaxFileSize) {
      setBottomSheetSize(true)
    } else {
      const reader = new window.FileReader()
      reader.onloadend = async () => {
        if (file?.type === 'application/pdf') {
          dispatch(
            setAnexos({
              anexo: {
                id: uuidv4(),
                nome: file.name,
                tipoExtensao: file.type,
                size: file.size,
                blobs: [
                  {
                    height: 200,
                    width: 200,
                    data: await fileToBase64(file),
                  },
                ],
              },
              tipo: 'CONTRACHEQUE',
            }),
          )
        } else {
          const image = new Image()
          image.src = reader.result as string
          image.onload = () => {
            dispatch(
              setAnexos({
                anexo: {
                  id: uuidv4(),
                  nome: file?.name || '',
                  tipoExtensao: file?.type || '',
                  size: file?.size,
                  blobs: [
                    {
                      height: image.height,
                      width: image.width,
                      data: image.src,
                    },
                  ],
                },
                tipo: 'CONTRACHEQUE',
              }),
            )
          }
        }
      }

      if (file?.type === 'application/pdf') {
        reader.readAsArrayBuffer(file)
      } else {
        reader.readAsDataURL(file as Blob)
      }
    }
  }

  const sendPhoto = useCallback(() => {
    dispatch(setIsLoading(true))
    dispatch(setIsError(false))
    dispatch(
      sendToS3({
        tipo: 'CONTRACHEQUE',
        anexos,
        callback: () => {
          dispatch(trackingRefin(StepRefin.ANEXO_DE_DOCUMENTOS_CONCLUIDO))
          navigate(routes.STATUS_DOCUMENTO)
        },
      }),
    )
  }, [anexos, dispatch, navigate])

  useEffect(() => {
    if (anexos.length > 0) {
      sendPhoto()
    }
  }, [anexos.length, sendPhoto])

  useEffect(() => {
    if (isDone) {
      Tag.enviarTagueamentoWeb({
        element_name: '',
        section_name: 'Anexo de documento',
        element_action: 'submit',
        c_page: window.location.href,
        redirect_url: `${process.env.REACT_APP_BIOMETRIA_MODULO}`,
        step: 'null',
      })
      navigate(routes.STATUS_DOCUMENTO)
    }
  }, [navigate, isDone])

  return (
    <Page
      footerTwoButtonsSpace="180px"
      stickyFooter={
        <>
          <CustomButton
            onClick={() => {
              dispatch(trackingRefin(StepRefin.FOTOGRAFAR_DOCUMENTO))
              navigate(routes.FOTO_CONTRA_CHEQUE)
            }}
          >
            <ColorWrapper color={Colors.GRAY500} fontWeight="700">
              <TextParagraph variant="body-3">Tirar uma foto agora</TextParagraph>
            </ColorWrapper>
            <CameraOn width={24} height={24} color={Colors.PRIMARY500} />
          </CustomButton>
          <CustomButton
            onClick={() => {
              document.getElementById('input-camera')?.click()
            }}
          >
            <ColorWrapper color={Colors.GRAY500} fontWeight="700">
              <TextParagraph variant="body-3">Prefiro anexar a foto</TextParagraph>
            </ColorWrapper>
            <Attachment width={24} height={24} color={Colors.PRIMARY500} />
            <InputFile
              id="input-camera"
              type="file"
              accept="image/*, application/pdf"
              onChange={async (e) => {
                dispatch(trackingRefin(StepRefin.ANEXAR_DOCUMENTO))
                anexarDocumentos(e)
              }}
            />
          </CustomButton>
        </>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 16px 0">
        <TextParagraph variant="headline-h1">
          Para enviar o documento, certifique-se que:
        </TextParagraph>
      </ColorWrapper>
      <Container>
        <LineDescription>
          <ListCheck width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              É possível ler todas as informações do documento na foto.
            </TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <CheckBack width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              Não há reflexos na foto. Se precisar, retire o plástico protetor.
            </TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <FrameDocument width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              O documento está centralizado e que nada está na frente dele.
            </TextParagraph>
          </DivLine>
        </LineDescription>
      </Container>
      {isLoading || openLoading ? (
        <BottomSheet
          onClose={() => {
            setOpenLoading(false)
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading width={30} height={30} />
          </div>
          <ColorWrapper
            color={Colors.GRAY500}
            fontWeight="700"
            margin="24px 0"
            style={{ textAlign: 'center' }}
          >
            <TextParagraph variant="body-1">Carregando arquivo...</TextParagraph>
          </ColorWrapper>
        </BottomSheet>
      ) : (
        <></>
      )}
      {bottomSheetSize ? (
        <BottomSheet onClose={() => setBottomSheetSize(false)}>
          <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="22px 0 16px 0">
            <TextParagraph variant="headline-h3">Tamanho máximo excedido</TextParagraph>
          </ColorWrapper>
          <TextParagraph variant="body-3">
            Os arquivos enviados devem somar no máximo 2MB de tamanho. Verifique o tamanho e tente
            novamente.
          </TextParagraph>
          <Button
            onClick={() => {
              setBottomSheetSize(false)
            }}
          >
            Fechar
          </Button>
        </BottomSheet>
      ) : (
        <></>
      )}
    </Page>
  )
}

export default ModoEnvioContracheque
