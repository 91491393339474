import { BaseServices, HttpMethod } from '@services/base'
import { PropostaApi } from '@store/refinanciamento/proposta/types'

import { BridgeService } from './bridge'

export interface RequestDadosProposta {
  numeroProposta: string | number
  cpf: string
  email: string
  telefone: string
}

export default (data: RequestDadosProposta, accessToken?: string) => {
  const pathname = BridgeService.isBrowser()
    ? `proposals/${data.numeroProposta}/full-detail`
    : process.env.REACT_APP_DADOS_COMPLETOS_PROPOSTA
  const method = BridgeService.isBrowser() ? HttpMethod.GET : HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<PropostaApi>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
